[data-component="VoiceReferenceConsole"] {
    .visualization {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 32px;
      padding: 0 16px;
      opacity: 0.5;
      pointer-events: none;
    }
  }