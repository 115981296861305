[data-component='App'] {
  height: 100%;
  width: 100%;
  position: relative;
}

.visualization {
  display: flex;
  width: 100%;
  height: 32px;
  margin-bottom: 8px;

  &-entry {
    flex: 1;
    height: 100%;
    
    canvas {
      width: 100%;
      height: 100%;
    }

    &.client {
      margin-right: 4px;
    }
    
    &.server {
      margin-left: 4px;
    }
  }
}